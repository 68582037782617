import React, { useEffect, useState, useCallback } from "react";
import {
  List,
  TextField,
  ShowButton,
  TextInput,
  Datagrid,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceField,
  EditButton,
  ChipField,
  DeleteButton,
  SearchInput,
  useTranslate,
  SimpleList,
  useDataProvider,
  useListContext,
  Loading,
  downloadCSV
} from "react-admin";
import Switch from '@mui/material/Switch';
import { default as Box } from '@material-ui/core/Box';
import { makeStyles, Chip, useMediaQuery } from '@material-ui/core';
import { default as MuiButton } from '@mui/material/Button';
import jsonExport from 'jsonexport/dist';

const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
  margin: {
    backgroundColor: "transparent",
    marginTop: "35px"
  },
}));

const exporter = users => {
  const userForExport = users.map(user => {
    const { used_referral_code, _id, sync_time, corporate_name, payment_histories, customer_id, corporate_id, updated_at, avatar, __v, vouchers, ...userForExport } = user; // omit backlinks and author
    userForExport.corporate_name = corporate_name.length && corporate_name[0].name; // For renaming or adding a field 
    if(payment_histories !== null){
      userForExport.paid_price = payment_histories.length ? payment_histories[0].price : "Nil"; // For renaming or adding a field 
    }else{
      userForExport.paid_price = "Nil";
    }
    
    userForExport.stripe_payment_id = customer_id // For renaming or adding a field 
    userForExport.full_name = user.fname + " " + user.lname// For renaming or adding a field 
    userForExport.membership_no = _id// For renaming or adding a field 
    return userForExport;
  });
  jsonExport(userForExport, {
    headers: ['membership_no', 'full_name', 'fname', 'lname', 'mobile_prefix'] // order fields in the export
  }, (err, csv) => {
    downloadCSV(csv, 'user'); // download as 'user.csv` file
  });
};

const ToggleVisibility = ({ record }) => {
  const dataProvider = useDataProvider();
  const [isActive, setIsActive] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getOne('users', { id: record._id }).then(({ data }) => {
      setIsActive(data.is_active);
    })
  }, [])

  const updateStatus = useCallback(() => {
    dataProvider.setUserStatus('users', { data: { id: record._id, is_active: !isActive } }).then(({ data }) => {
      setIsActive(data.is_active);
    })
  });

  const handleToggleVisibility = updateStatus;

  return (
    <Switch
      edge="end"
      onChange={handleToggleVisibility}
      checked={isActive}
    />
  )
}

const MobileView = ({ record, ...props }) => {
  const translate = useTranslate();
  return <SimpleList
    primaryText={record => (
      <>
        <div><strong>{record.username}</strong></div>
      </>
    )
    }
    secondaryText={record => (
      <>
        <div><strong>{translate('resources.users.fields.email')}: </strong>{record.email}</div>
        <div><strong>{translate('resources.users.fields.card_num')}: </strong>{record.card_num}</div>
      </>
    )
    }
    tertiaryText={record => (<>
      <ReferenceField reference="roles" source="role">
        <ChipField source="name" variant="outlined" />
      </ReferenceField>
    </>)
    }
    linkType="edit"
  />
}

const ListView = ({ permissions, resource, ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = useQuickFilterStyles();
  const QuickFilter = ({ label, handleClick }) => {
    return <Chip className={classes.chip} label="quickfilter" />;
  };
  const postFilters = [
    <TextInput source="corporate_name" alwaysOn className={classes.margin} />,
    <TextInput source="fullname" alwaysOn />,
  ];

  const LoadingProp = ({ children }) => {
    const { loading } = useListContext();
    return loading ? <Loading /> : children;
  }

  return (
    <List {...props} filters={postFilters} exporter={exporter}>
      {
        isSmall ? <MobileView record="record" />
          :
          <LoadingProp >
            <Datagrid>
              <ReferenceField reference="corporate" source="corporate_id">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="fname" />
              <TextField source="user_type" />
              <TextField source="email" />
              <EditButton />
            </Datagrid>
          </LoadingProp>
      }
    </List >
  );
};

export default ListView;
