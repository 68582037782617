import { lsTokenKey } from "./constant";
const baseURL = process.env.REACT_APP_API_URI;

const fetchAPI = () => {
  const token = localStorage.getItem(lsTokenKey);

  const getMerchantType = async () => {
    const res = await fetch(`${baseURL}/merchant-type`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res;
  };

  const uploadImage = async (formData) => {
    const res = await fetch(`${process.env.REACT_APP_UPLOAD_URL}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    return res;
  };

  const getGrouppedVouchers = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/voucher/groupped`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res;
  };

  const getOneVoucherById = async (id) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/voucher/groupped/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res;
  };

  const getGeneralAll = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res;
  };

  const updateGeneral = async (data) => {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return res;
  };

  return {
    getMerchantType,
    uploadImage,
    getGrouppedVouchers,
    getOneVoucherById,
    updateGeneral,
    getGeneralAll,
  };
};

export default fetchAPI();
