import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import { useInput } from "react-admin";

import "./sliderInput.scss";

const CustomSlider = ({
  label,
  min = 0,
  max = 1000,
  step = 50,
  value: v,
  ...rest
}) => {
  const {
    input: { onChange, value },
    meta,
  } = useInput(rest);
  const [sliderValue, setSliderValue] = useState(v ? v : [min, max]);

  const onSliderChange = (e, returnValue) => {
    setSliderValue(returnValue);
    onChange(returnValue);
  };

  return (
    <div className="slider-container">
      <div className="label">{label}</div>
      <Box sx={{ width: 300 }}>
        <div className="slider">
          <div>{sliderValue[0]}</div>
          <Slider
            value={sliderValue}
            onChange={onSliderChange}
            {...meta}
            min={min}
            max={max}
            step={step}
            valueLabelDisplay="auto"
          />
          <div>{sliderValue[1]}</div>
        </div>
      </Box>
    </div>
  );
};

export default CustomSlider;
