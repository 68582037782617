import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import { EditButton, SaveButton } from "ra-ui-materialui";
import TextField from "../common/Input";
import RichTextEditor from "../common/RichTextEditor";
import fetchAPI from "../../config/api";

import "./style.scss";

// TODO: change save data

const General = () => {
  const [formState, setFormState] = useState({
    email: "",
    whatsapp: "",
  });
  const [tncState, setTncState] = useState("");

  const [isReadOnly, setIsReadOnly] = useState(true);

  const onEditButtonClick = (e) => {
    e.preventDefault();
    setIsReadOnly(false);
  };

  const onSaveClick = async () => {
    const res = await fetchAPI.updateGeneral({
      ...formState,
      terms_and_conditions: tncState,
    });

    if (res.status === 200) {
      window.location.reload();
    }
  };

  const onTextChange = (fieldName) => (e) => {
    setFormState({ ...formState, [fieldName]: e.target?.value });
  };

  const onRichTextChange = (val) => {
    setTncState(val);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchAPI.getGeneralAll();
      const json = await res.json();

      setFormState({ email: json.email, whatsapp: json.whatsapp });
      setTncState(json.terms_and_conditions);
    };

    fetchData();
  }, [setFormState]);

  return (
    <Card variant="outlined" className="general-show-view">
      <EditButton onClick={onEditButtonClick} />
      <TextField
        name="email"
        value={formState?.email}
        readOnly={isReadOnly}
        onChange={onTextChange("email")}
      />
      <TextField
        name="whatsapp"
        value={formState?.whatsapp}
        readOnly={isReadOnly}
        onChange={onTextChange("whatsapp")}
      />
      <h5>Terms and Conditions</h5>
      <div
        style={{ display: isReadOnly ? "block" : "none" }}
        dangerouslySetInnerHTML={{ __html: tncState }}
      ></div>
      <RichTextEditor
        style={{ display: isReadOnly ? "none" : "block" }}
        value={tncState || ""}
        onChange={onRichTextChange}
        defaultValue={tncState}
      />
      <SaveButton
        className="general-view-save-bt"
        disabled={isReadOnly ? true : false}
        handleSubmitWithRedirect={onSaveClick}
      />
    </Card>
  );
};

export default General;
