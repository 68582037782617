import React, { useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  DateInput,
  RadioButtonGroupInput,
  SelectInput,
  required,
  FormDataConsumer,
  useEditController,
} from "react-admin";
import dayjs from "dayjs";
import { uploadImage } from "../../services/quillUtils";
import RichTextInput from "ra-input-rich-text";
import "./voucher.scss";

const dateValidation = (value) => {
  const today = dayjs().startOf('day').format();
  if (dayjs(value).startOf('day').format() < today) {
    return 'You cannot select any day in the past';
  }
  return undefined;
};

// simple form fail to detect change so write a custom one
const EditForm = (props) => {

  const configureQuill = (quill) => {
    uploadImage(quill);
  };

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <div className="voucher-rich-text-editor">
          <RichTextInput
            source="description"
            validate={required()}
            toolbar={[["bold", "italic", "underline", "link", "image"]]}
            configureQuill={configureQuill}
          />
        </div>        
        <DateInput source="date_available" validate={[required(), dateValidation]} />
        <RadioButtonGroupInput
          source="is_limited"
          choices={[
            { id: true, name: "Yes" },
            { id: false, name: "No" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.is_limited === false) {
              return <DateInput source="expire_at" />;
            } else {
              return (
                <RadioButtonGroupInput
                  helperText="with a expire date or duration?"
                  source="expire_method"
                  choices={[
                    { id: "by_date", name: "By Date" },
                    { id: "by_duration", name: "By Duration" },
                  ]}
                />
              );
            }
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.expire_method === "by_duration") {
              return (
                <RadioButtonGroupInput
                  source="duration"
                  choices={[
                    { id: "1", name: "1 Month" },
                    { id: "2", name: "2 Months" },
                    { id: "3", name: "3 Months" },
                    { id: "6", name: "6 Months" },
                    { id: "12", name: "12 Months" },
                  ]}
                />
              );
            }

            if (formData.expire_method === "by_date") {
              return <DateInput source="expire_at" />;
            }

            return <></>;
          }}
        </FormDataConsumer>
        <RadioButtonGroupInput
          source="voucher_for"
          choices={[
            { id: "merchant", name: "Merchant" },
            { id: "merchant_type", name: "Merchant Type" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.voucher_for === "merchant" ? (
              <ReferenceArrayInput reference="merchant" source="merchant">
                <SelectArrayInput />
              </ReferenceArrayInput>
            ) : (
                <ReferenceArrayInput
                  reference="merchant-type"
                  source="merchant_type"
                >
                  <SelectArrayInput />
                </ReferenceArrayInput>
              )
          }
        </FormDataConsumer>
        <SelectInput
          source="user_group"
          choices={[
            { id: "corporate", name: "Corporate Only" },
            { id: "subscriber", name: "Subscriber Only" },
            { id: "all", name: "All " },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.user_group === "corporate" && (
              <ReferenceArrayInput source="corporate_id" reference="corporate">
                <SelectArrayInput optionText="name" />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.is_limited && (
              <SelectInput
                source="how_to_get"
                label="Method of Getting"
                choices={[
                  {
                    id: "exchange_by_mission",
                    name: "Exchange by mission",
                  },
                  { id: "free_for_all", name: "Free for all" },
                  { id: "is_welcome_gift", name: "Welcome Gift" },
                ]}
              />
            )
          }
        </FormDataConsumer>
        <div className="voucher-rich-text-editor">
          <RichTextInput
            source="terms_and_conditions"
            validate={required()}
            toolbar={[["bold", "italic", "underline", "link", "image"]]}
            configureQuill={configureQuill}
          />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EditForm;
