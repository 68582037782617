import React from "react";
import { useRecordContext } from "react-admin";
import QRCode from "qrcode.react";
import "./qrCodeList.scss";

const QRCodeList = (props) => {
  const voucherBasePath = process.env.REACT_APP_BASE_URI;
  const record = useRecordContext(props);
  return (
    <div className="qr-code-list">
      {record.merchant.map((merchant, key) => {
        return (
          <div className="qr-code-container" key={key}>
            <div>
              {merchant.merchant_name} - {merchant.branch_name}
            </div>
            <div className="qr-code">
              <QRCode
                value={`${voucherBasePath}/frontend/voucher/${merchant.voucher_id}/${merchant.merchant_id}/redeem`}
                size={256}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QRCodeList;
