import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
// import DialogContentText from "@material-ui/core/DialogContentText ";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useDelete } from "react-admin";
import { styled } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

const StyledDeleteButton = styled("button")(({ theme }) => ({
  color: theme.palette.error.main,
  background: "transparent",
  border: 0,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));
const StyledCancelButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  background: theme.palette.primary.main,
}));

const DeleteWithConfirmation = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteOne, { loading }] = useDelete();
  useEffect(() => {
    if (!loading && deleteClicked) {
      window.location.reload(false);
    }
  }, [loading, deleteClicked]);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const onDeleteClick = () => {
    deleteOne(props.resource, props.record.id);
    setIsDialogOpen(false);
    setDeleteClicked(true);
  };

  return (
    <>
      <StyledDeleteButton onClick={openDialog}>
        <DeleteIcon />
        Delete
      </StyledDeleteButton>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          Are you sure you want to remove this item? Other items that is related
          to it will be affected.
        </DialogContent>
        <DialogActions>
          <StyledCancelButton variant="contained" onClick={closeDialog}>
            Cancel
          </StyledCancelButton>
          <StyledDeleteButton onClick={onDeleteClick}>
            <DeleteIcon />
            Delete
          </StyledDeleteButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteWithConfirmation;
