import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  required,
  FormDataConsumer,
  ArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  RadioButtonGroupInput
} from "react-admin";
import fetchAPI from "../../config/api";
import PhotoEditField from "../common/PhotoField";
import SliderInput from "../common/SliderInput";

const transformInputValue = (data) => {
  if (!data.budget) return data;
  const budget = JSON.parse(data.budget);
  return {
    ...data,
    min_budget: data.budget ? budget["min"] : 0,
    max_budget: data.budget ? budget["max"] : 0,
  };
};

const EditForm = (props) => {
  const [merchantTypes, setMerchantTypes] = useState([]);

  useEffect(() => {
    const getMerchantType = async () => {
      const res = await fetchAPI.getMerchantType();
      if (res.status === 200) {
        const json = await res.json();
        setMerchantTypes(json);
      }
    };

    getMerchantType();
  }, []);

  return (
    <Edit {...props} transform={transformInputValue}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ImageInput source="picture" accept="image/*" multiple={false}>
          <PhotoEditField {...props} />
        </ImageInput>
        <ReferenceArrayInput
          label="Type"
          reference="merchant-type"
          source="type"
          validate={required()}
        >
          <SelectArrayInput />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const diningID = merchantTypes.filter(
              (type) => type.name === "Dining"
            );
            if (!formData.type || !diningID || diningID.length === 0)
              return <></>;

            if (formData.type.includes(diningID[0].id)) {
              return (
                <>
                  <div>
                    <ReferenceArrayInput
                      label="Cuisine"
                      reference="cuisine"
                      source="cuisine"
                      validate={required()}
                    >
                      <SelectArrayInput />
                    </ReferenceArrayInput>
                  </div>
                  <div>
                    <ReferenceArrayInput
                      label="Good For"
                      reference="good-for"
                      source="good_for"
                      validate={required()}
                    >
                      <SelectArrayInput />
                    </ReferenceArrayInput>
                  </div>
                  <div>
                    <RadioButtonGroupInput
                      source="budget"
                      choices={[
                        {
                          id: JSON.stringify({ min: 0, max: 400 }),
                          name: "below $400",
                        },
                        {
                          id: JSON.stringify({ min: 401, max: 800 }),
                          name: "$401-$800",
                        },
                        {
                          id: JSON.stringify({ min: 801, max: null }),
                          name: "above $801",
                        },
                      ]}
                    />
                  </div>
                </>
              );
            }
          }}
        </FormDataConsumer>
        <TextInput
          source="description"
          multiline
          validate={required()}
          className="merchant-description"
        />
        <TextInput source="email" validate={required()} />
        <TextInput source="phone" validate={required()} />
        <ArrayInput source="branches" validate={required()}>
          <SimpleFormIterator validate={required()}>
            <TextInput label="Name" source="name" validate={required()} />
            <TextInput label="Phone" source="phone" validate={required()} />
            <TextInput label="Address" source="address" validate={required()} />
            <ReferenceInput
              label="District"
              source="district"
              reference="district"
              validate={required()}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditForm;
