import RichTextInput from "ra-input-rich-text";
import React from "react";
import { Edit, SimpleForm, TextInput, required } from "react-admin";

const CreateForm = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="question" validate={required()} />
        <RichTextInput
          source="answer"
          toolbar={[["bold", "italic", "underline", "link"]]}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CreateForm;
