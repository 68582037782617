import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
} from "react-admin";

const EditForm = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ArrayInput source="email" validate={required()}>
          <SimpleFormIterator>
            <TextInput label="Email Suffix" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="discount_rate" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default EditForm;
