import React, { useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  DateInput,
  RadioButtonGroupInput,
  SelectInput,
  FormDataConsumer,
  required,
  maxLength,
} from "react-admin";
import { uploadImage } from "../../services/quillUtils";
import { voucherTransform } from "../../services/transform";
import dayjs from "dayjs";
import RichTextInput from "ra-input-rich-text";
import "./voucher.scss";

const dateValidation = (value) => {
  const today = dayjs().startOf('day').format();
  if (dayjs(value).startOf('day').format() < today) {
    return 'You cannot select any day in the past';
  }
  return undefined;
};

const CreateForm = (props) => {
  const configureQuill = (quill) => {
    uploadImage(quill);
  };  
  return (
    <Create {...props} transform={voucherTransform}>
      <SimpleForm>
        <TextInput source="title" validate={required()} />
        <div className="voucher-rich-text-editor">
          <RichTextInput
            source="description"
            validate={required()}
            toolbar={[["bold", "italic", "underline", "link", "image"]]}
            configureQuill={configureQuill}
          />
        </div>
        <DateInput source="date_available" defaultValue={Date.now()} validate={[required(), dateValidation]} />
        <RadioButtonGroupInput
          source="is_limited"
          choices={[
            { id: 1, name: "Yes" },
            { id: 0, name: "No" },
          ]}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.is_limited === 0) {
              return <DateInput source="expire_at" validate={required()} />;
            } else {
              return (
                <RadioButtonGroupInput
                  helperText="with an expire date or duration?"
                  source="expire_method"
                  choices={[
                    { id: "by_date", name: "By Date" },
                    { id: "by_duration", name: "By Duration" },
                  ]}
                  validate={required()}
                />
              );
            }
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.expire_method === "by_duration") {
              return (
                <RadioButtonGroupInput
                  source="duration"
                  choices={[
                    { id: "1", name: "1 Month" },
                    { id: "2", name: "2 Months" },
                    { id: "3", name: "3 Months" },
                    { id: "6", name: "6 Months" },
                    { id: "12", name: "12 Months" },
                  ]}
                  validate={required()}
                />
              );
            }

            if (formData.expire_method === "by_date") {
              return <DateInput source="expire_at" validate={required()} />;
            }

            return <></>;
          }}
        </FormDataConsumer>

        <RadioButtonGroupInput
          helperText="Voucher for a single merchant or a type of merchant?"
          source="voucher_for"
          choices={[
            { id: "merchant", name: "Merchant" },
            { id: "merchant_type", name: "Merchant Type" },
          ]}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.voucher_for === "merchant" ? (
              <ReferenceArrayInput reference="merchant" source="merchants">
                <SelectArrayInput optionText="name" validate={required()} />
              </ReferenceArrayInput>
            ) : (
                <ReferenceArrayInput
                  reference="merchant-type"
                  source="merchant_types"
                >
                  <SelectArrayInput validate={required()} />
                </ReferenceArrayInput>
              )
          }
        </FormDataConsumer>
        <SelectInput
          source="user_group"
          choices={[
            { id: "corporate", name: "Corporate Only" },
            { id: "subscription", name: "Subscriber Only" },
            { id: "all", name: "All" },
          ]}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.user_group === "corporate" && (
              <ReferenceArrayInput source="corporate_id" reference="corporate">
                <SelectArrayInput optionText="name" validate={required()} />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.is_limited === 1 && (
              <SelectInput
                source="how_to_get"
                label="Method of Getting"
                choices={[
                  { id: "exchange_by_mission", name: "Exchange by mission" },
                  { id: "free_for_all", name: "Free for all" },
                  { id: "is_welcome_gift", name: "Welcome Gift" },
                ]}
                validate={required()}
              />
            )
          }
        </FormDataConsumer>
        <div className="voucher-rich-text-editor">
          <RichTextInput
            source="terms_and_conditions"
            validate={required()}
            toolbar={[["bold", "italic", "underline", "link", "image"]]}
            configureQuill={configureQuill}
          />
        </div>        
      </SimpleForm>
    </Create>
  );
};

export default CreateForm;
