import { lsTokenKey } from "../config/constant";
const api = process.env.REACT_APP_API_URI;

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const request = new Request(`${api}/auth/signin`, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });

      const res = await fetch(request);

      if (res.status === 200) {
        const { token } = await res.json();
        await localStorage.setItem(lsTokenKey, token);
      } else {
        return Promise.reject();
      }
    } catch (e) {
      throw new Error("Network error. Please contact support");
    }
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(lsTokenKey);
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: (params) => {
    return localStorage.getItem(lsTokenKey)
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(lsTokenKey);
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: (params) => Promise.resolve(),
};

export default authProvider;
