import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  BooleanField,
  DeleteButton,
} from "react-admin";

const ListView = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="title" />
        <BooleanField source="is_show" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" sortByOrder="DESC" />
        <EditButton basePath="/article" label="Edit" record={props.record} />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default ListView;
