import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  SelectInput,
  FormDataConsumer,
  required,
  number,
  maxValue,
  minValue,
} from "react-admin";
import TextField from "@mui/material/TextField";

const FreeTrialReferenceField = () => (
  <><TextInput
    source="reference_on_model"
    label="Reference Model - Null"
    initialValue={null}
    disabled
    sx={{ display: "none" }}
  />
    <br />
    <TextInput
      source="number_of_code_generate"
      validate={[required(), number(), maxValue(100), minValue(1)]}
      label="Number of codes to generate"
      sx={{ marginBottom: 3 }}
    />
  </>

)
const CorporateReferenceField = () => (
  <>
    <ReferenceArrayInput
      source="reference"
      reference="corporate"
      validate={required()}
    >
      <SelectInput optionText="name" validate={required()} />
    </ReferenceArrayInput>
    <br />
    <TextInput
      source="reference_on_model"
      validate={required()}
      label="Reference Model - Corporate"
      initialValue="Corporate"
      disabled
      sx={{ display: "none" }}
    />
    <br />
    <TextInput
      source="number_of_code_generate"
      validate={[required(), number(), maxValue(100), minValue(1)]}
      label="Number of codes to generate"
      sx={{ marginBottom: 3 }}
    />
  </>
);

const UserReferenceField = () => (
  <>
    <ReferenceArrayInput
      source="reference"
      reference="user"
      validate={required()}
    >
      <SelectInput optionText="fullname" validate={required()} />
    </ReferenceArrayInput>
    <br />
    <TextInput
      source="reference_on_model"
      validate={required()}
      label="Reference Model - User"
      initialValue="User"
      disabled
    />
  </>
);

const CreateForm = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceArrayInput
          source="stripe_pricing_scheme"
          reference="pricing-scheme"
          validate={required()}
        >
          <SelectInput optionText="descriptive_title" validate={required()} />
        </ReferenceArrayInput>
        <RadioButtonGroupInput
          source="tag"
          validate={required()}
          choices={[
            { id: "referral", name: "referral" },
            { id: "activation_code", name: "activation_code" },
            { id: "promotional_code", name: "promotional_code" },
            { id: "free_trial_code", name: "free_trial_code" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return formData.tag !== "referral" ? (
              <CorporateReferenceField />
            ) : (
                <UserReferenceField />
              );
          }}
        </FormDataConsumer>
        <DateInput source="validity" validate={required()} />
        <RadioButtonGroupInput
          source="reusable"
          validate={required()}
          choices={[
            { id: true, name: "true" },
            { id: false, name: "false" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateForm;
