import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";
import DeleteWithConfirmation from "../common/DeleteWithConfirmation";

const ListView = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="stripe_price_id" />
        <TextField source="descriptive_title" />
        <TextField source="price" />
        <TextField source="billing_unit" />
      </Datagrid>
    </List>
  );
};

export default ListView;
