import RichTextInput from "ra-input-rich-text";
import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import "./style.scss";

const CreateForm = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="question" validate={required()} />
        <RichTextInput
          source="answer"
          toolbar={[["bold", "italic", "underline", "link"]]}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateForm;
