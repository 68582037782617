import React from "react";
import {
  List,
  Datagrid,
  TextField,
  RichTextField,
  EditButton,
  DeleteButton,
} from "react-admin";

const ListView = (props) => {
  return (
    <List {...props}>
      <Datagrid expand={<RichTextField source="answer" />}>
        <TextField source="question" />
        <EditButton basePath="/faq" label="Edit" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default ListView;
