import React from "react";
import { EditButton, List, Datagrid, TextField, DateField, ArrayField, SingleFieldList, ChipField, ReferenceField } from "react-admin";
import DeleteWithConfirmation from "../common/DeleteWithConfirmation";
import { Typography } from '@mui/material';
const moment = require('moment-timezone')

const PromotionCodes = ({ record }) => {
  if (record.promotional_code) {
    const { promotional_code } = record;
    return promotional_code.length && promotional_code.map((val) => (
      <Typography>{val}</Typography>
    ))
  }
  else return <></>
}
const ClaimedCodes = ({ record }) => {
  if (record.claimed) {
    const codesThatHaveBeenClaimed = record.claimed;
    return codesThatHaveBeenClaimed.length && codesThatHaveBeenClaimed.map(({ time, code }) => (
      <Typography>{code}</Typography>
    ))
  }
  else return <></>
}

const ClaimedTime = ({ record }) => {
  if (record.claimed) {
    const codesThatHaveBeenClaimed = record.claimed;
    return codesThatHaveBeenClaimed.length && codesThatHaveBeenClaimed.map(({ date }) => (
      <Typography>{moment.tz(date, "Asia/Hong_Kong").format('DD/MM/YYYY, h:mm:ss a')}</Typography>
      // <Typography>{date}</Typography>
    ))
  } else return <></>
}

const ReferencedFrom = ({ record }) => {
  if (!record.reference_on_model) return <></>;
  const objectType = record.reference_on_model.toLowerCase();
  // objectType == 'User'

  return (
    <ReferenceField source="reference" reference={objectType}>
      <TextField source={objectType == 'user' ? "fullname" : "name"} />
    </ReferenceField>
  )
}

const PricingDetails = ({ record }) => {
  const { billing_unit, currency, descriptive_title, price } = record;
  return (
    <><Typography variant="h6">{descriptive_title}</Typography>
      <Typography variant="body2">{`${currency} : ${price} / ${billing_unit}`}</Typography>
    </>
  )
}

const ListView = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <ReferencedFrom source="reference" label="sponsor" />
        <TextField source="tag" />
        <ReferenceField source="stripe_pricing_scheme" reference="pricing-scheme" label="Promotion Summary">
          {/* <TextField source="price" /> */}
          <PricingDetails />
        </ReferenceField>
        <DateField source="validity" />
        <PromotionCodes source="promotional_code" />
        <ClaimedCodes source="claimed" />
        <ClaimedTime source="claimed" label="claimed_time" />
        {/* <EditButton basePath="/promotional-code" />  */}
      </Datagrid>
    </List>
  );
};

export default ListView;
