
import React from "react";
import TextField from "@material-ui/core/TextField";

const CustomTextField = ({ value, name, readOnly, ...rest }) => {
  return (
    <TextField
      className="custom-text-field"
      InputProps={{
        readOnly,
      }}
      variant={readOnly ? "standard" : "filled"}
      label={name}
      value={value}
      {...rest}
    />
  );
};

export default CustomTextField;
