import React from "react";
import {
  List,
  ArrayField,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  EditButton,
  ReferenceField,
  downloadCSV
} from "react-admin";
import "./listView.scss";
import QRCodeList from "./QRCodeList";
import DeleteWithConfirmation from "../../components/common/DeleteWithConfirmation";
import jsonExport from 'jsonexport/dist';
import { Typography } from '@mui/material';

const exporter = voucheres => {
  const voucherForExport = voucheres.map(voucher => {
    const { _id, batch_id, merchant, merchant_type, expire_method, branch, deleted, updated_at, created_at, __v, corporate_id, id, ...voucherForExport } = voucher; // omit backlinks and author
    // voucherForExport.author_name = voucher.author.name; // For renaming or adding a field 
    return voucherForExport;
  });
  jsonExport(voucherForExport, {
    // headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
  }, (err, csv) => {
    downloadCSV(csv, 'voucher'); // download as 'voucher.csv` file
  });
};

const MerchantName = ({ source, record, ...props }) => {
  const merchantName = (record.merchant && record.merchant.length) ? record.merchant[0].merchant_name : "";
  return <Typography variant="body2" >{merchantName}</Typography>
}
const ListView = (props) => {
  return (
    <List {...props} exporter={exporter}>
      <Datagrid expand={QRCodeList}>
        <TextField source="title" />
        <TextField source="user_group" />
        <DateField source="date_available" />
        <BooleanField source="is_limited" />
        <TextField source="how_to_get" />
        <MerchantName source="merchant" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ListView;
