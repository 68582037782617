import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  RadioButtonGroupInput,
  required,
} from "react-admin";

const CreateForm = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="stripe_price_id" validate={required()} />
        <TextInput source="descriptive_title" validate={required()} />
        <TextInput source="price" validate={required()} />
        <RadioButtonGroupInput
          source="billing_unit"
          validate={required()}
          choices={[
            { id: "month", name: "month" },
            { id: "year", name: "year" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateForm;
