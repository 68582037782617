const getCurrentTime = () => {
  const today = new Date();
  return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
};

export const voucherTransform = (data) => {
  const { date_available } = data; // similar to 2021-11-24

  // current time is need, so that coupon immediately avaliable can be displayed
  const withCurrentTime = new Date(`${date_available} ${getCurrentTime()}`);

  return {
    ...data,
    date_available: new Date(withCurrentTime),
    by_date: data.by_date ? new Date(data.by_date) : "",
  };
};
