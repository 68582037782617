import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  DeleteButton,
  ChipField,
  ArrayField,
  ReferenceField,
  useRecordContext,
} from "react-admin";

const EmailRow = ({ id, record, resource }) => {
  return record.email.map((r) => {
    return <div key={r.suffix}>{r.suffix}</div>;
  });
};

const EmailField = (props) => {
  const record = useRecordContext(props);
  return (
    <div>
      {record.email.map((email) => (
        <div key={email}>{email}</div>
      ))}
    </div>
  );
};

const ListView = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="name" />
        <EmailField />
        <ReferenceField reference="pricing-scheme" source="discount_rate" label="Discounted Price">
          <TextField source="price" />
        </ReferenceField>
        <ReferenceField reference="pricing-scheme" source="discount_rate" label="Pricing Scheme">
          <TextField source="descriptive_title" />
        </ReferenceField>
        <EditButton basePath="/corporate" label="Edit" record={props.record} />
      </Datagrid>
    </List>
  );
};

export default ListView;
