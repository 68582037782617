import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  TextInput,
  ReferenceArrayField,
  useRecordContext,
  downloadCSV,
  useDataProvider
} from "react-admin";
import DeleteWithConfirmation from "../common/DeleteWithConfirmation";
import { makeStyles } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import jsonExport from 'jsonexport/dist';
// TODO: add tab to switch to different type of merchant


const ToggleVisibility = ({ record }) => {
  const dataProvider = useDataProvider();
  const [visbility, setVisibility] = useState(record.is_popular);
  const [error, setError] = useState();
  const updateStatus = useCallback(() => {
    dataProvider.toggelDisplay('merchant', { data: { id: record._id } }).then(({ data }) => {
      setVisibility(data.status);
    })
  });

  const handleToggleVisibility = updateStatus;

  return (
    <Switch
      edge="end"
      onChange={handleToggleVisibility}
      checked={visbility}
    />
  )
}

// const exporter = merchants => {
//   const merchantForExport = merchants.map(merchant => {
//     const { _id, type, cuisine, picture, good_for, branches, updated_at, created_at, __v, vouchers, id, ...merchantForExport } = merchant; // omit backlinks and author
//     merchantForExport.type_name = type.length && type[0].name; // For renaming or adding a field 
//     merchantForExport.cuisine_name = cuisine.length && cuisine[0].name; // For renaming or adding a field 
//     merchantForExport.branch_address = branches.length && branches[0].address; // For renaming or adding a field 
//     merchantForExport.branch_phone = branches.length && branches[0].phone; // For renaming or adding a field 
//     merchantForExport.branch_name = branches.length && branches[0].name; // For renaming or adding a field 
//     return merchantForExport;
//   });
//   jsonExport(merchantForExport, {
//     headers: ['name', 'branch_name', 'branch_address', 'branch_phone', 'phone'] // order fields in the export
//   }, (err, csv) => {
//     downloadCSV(csv, 'merchant'); // download as 'voucher.csv` file
//   });
// };

const useQuickFilterStyles = makeStyles(theme => ({
  textInput: {
    backgroundColor: "transparent",
    marginTop: "35px"
  }
}));


const ListView = (props) => {
  const classes = useQuickFilterStyles();
  const postFilters = [
    <TextInput source="name" alwaysOn className={classes.textInput} />,
  ];
  return (
    <List {...props} filters={postFilters} bulkActionButtons={false}>
      <Datagrid>
        <ReferenceField reference="user" source="user">
          <TextField source="fullname" />
        </ReferenceField>
        <ReferenceField reference="user" source="user">
          <TextField source="mobile_prefix" />
        </ReferenceField>
        <ReferenceField reference="user" source="user">
          <TextField source="mobile" />
        </ReferenceField>
        <ReferenceField reference="article" source="source" label="article title">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="localizedCreatedAt" />
        <DeleteWithConfirmation />
      </Datagrid>
    </List>
  );
};

export default ListView;
