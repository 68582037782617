import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { lsTokenKey } from "../config/constant";
import fetchAPI from "../config/api";
import { checkFilterOrSortExists, isDataMatchedWithFilter, sortData } from './frontendFilter';
import queryString from 'query-string';

const httpClient = async (url, options = {}) => {
  const token = await localStorage.getItem(lsTokenKey);
  options.headers = new Headers({ Accept: "application/json" });
  options.headers.set("Authorization", `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

const updateResourceWithImageUploadField = async (params) => {
  let formData = new FormData();
  formData.append("file", params.data.picture.rawFile);
  const res = await fetchAPI.uploadImage(formData);
  return await res.json();
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URI,
  httpClient,
  "X-Total-Count"
);
const dataProviderUpload = {
  ...dataProvider,

  create: async (resource, params) => {
    if (resource === "merchant" || resource === "article") {
      let path;
      if (params.data.picture) {
        const json = await updateResourceWithImageUploadField(params);
        path = json.path;
      }
      else {
        path = "";
      }

      return dataProvider.create(resource, {
        ...params,
        data: { ...params.data, picture: path },
      });
    }

    // fallback to the default implementation
    return dataProvider.create(resource, params);
  },
  update: async (resource, params) => {
    if (
      (resource === "merchant" || resource === "article") &&
      typeof params.data.picture !== "string"
    ) {
      let path;
      if (params.data.picture) {
        const json = await updateResourceWithImageUploadField(params);
        path = json.path;
      }
      else {
        path = "";
      }
      return dataProvider.update(resource, {
        ...params,
        data: { ...params.data, picture: path },
      });
    }

    return dataProvider.update(resource, params);
  },
  delete: async (resource, params) => {
    return dataProvider.delete(resource, {
      ...params,
    });
  },
  getList: (resource, params) => {
    const { filter, sort } = params;
    if (checkFilterOrSortExists(resource, filter, sort)) {
      const stringifiedFilter = queryString.stringify(filter);
      return new Promise((resolve, reject) => {
        httpClient(`${process.env.REACT_APP_API_URI}/${resource}?${stringifiedFilter}`, {
          method: 'GET',
        }).then(({ json }) => {
          const int = json.length;
          const filtered = Object.keys(filter).length && json.filter(da => {
            return isDataMatchedWithFilter(da, filter);
          })
          const sorted = filtered ? sortData(filtered, sort) : sortData(json, sort);
          resolve({ data: sorted, total: int });
        }).catch(err => {
          reject(err);
        });
      })
    }
    else {
      return dataProvider.getList(resource, params);
    }
  },
  toggelDisplay: (resource, params) => {
    return httpClient(`${process.env.REACT_APP_API_URI}/${resource}/toggle-merchant-popularity/${params.data.id}`, {
      method: 'GET',
    }).then(({ json }) => ({
      data: { status: json.status },
    }));
  },
};

export default dataProviderUpload;
