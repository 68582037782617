import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceField,
  DateInput,
  ReferenceInput,
  TextField
} from "react-admin";

const EditForm = (props) => {

  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput label="corporate" source="corporate_id" reference="corporate">
          <TextInput source="name" disabled />
        </ReferenceInput>
        <TextInput source="fname" />
        <TextInput source="lname" />
        <TextInput source="mobile" />
        <TextInput source="email" />
        <TextInput source="user_type" disabled />
        <TextInput source="referral_code" disabled />
        <TextInput source="expire_date" disabled />
        <TextInput source="password" type="password" label="New Password" />
      </SimpleForm>
    </Edit>
  );
};

export default EditForm;
