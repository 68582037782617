import React from "react";
import { List, Datagrid, TextField } from "react-admin";

const ListView = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="name" />
        <TextField />
      </Datagrid>
    </List>
  );
};

export default ListView;
