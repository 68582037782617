import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  RadioButtonGroupInput,
  BooleanInput,
  required,
  maxLength,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { uploadImage } from "../../services/quillUtils";
import PhotoEditField from "../common/PhotoField";
import "./article.scss";

const CreateForm = (props) => {
  const configureQuill = (quill) => {
    uploadImage(quill);
  };

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" validate={[required(), maxLength(250)]} />
        <ImageInput source="picture" accept="image/*" multiple={false}>
          <PhotoEditField {...props} />
        </ImageInput>
        {/* handle img post */}
        <div className="article-rich-text-editor">
          <RichTextInput
            source="content"
            validate={required()}
            toolbar={[["bold", "italic", "underline", "link", "image"]]}
            configureQuill={configureQuill}
          />
        </div>
        <RadioButtonGroupInput
          source="category"
          choices={[
            { id: "hot", name: "Hot" },
            { id: "others", name: "Others" },
            { id: "recommanded", name: "Recommanded" },
          ]}
          validate={required()}
        />
        <BooleanInput source="is_show" label="Is avaliable?" />
      </SimpleForm>
    </Edit>
  );
};

export default CreateForm;
