import React from "react";
import { Show, SimpleForm, TextField, SimpleShowLayout } from "react-admin";

const ShowView = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowView;
