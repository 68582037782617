import { Admin, Resource, defaultTheme } from "react-admin";
import Corporate from "./components/corporate";
import User from "./components/user";
import Cuisine from "./components/cuisine";
import District from "./components/district";
import MerchantType from "./components/merchantType";
import Merchant from "./components/merchant";
import LuckyDraw from "./components/luckyDraw";
import Voucher from "./components/voucher";
import PricingScheme from "./components/pricingScheme";
import PromotionalCode from "./components/promotionalCode";
import Article from "./components/article";
import FAQ from "./components/faq";
import GoodFor from "./components/goodFor";
import Record from "./components/record";
import customRoutes from "./config/customRoutes";
import dataProvider from "./services/dataProviderUpload";
import authProvider from "./services/authProvider";
import Menu from "./components/Menu";

const customTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    secondary: { main: "#0E0057" },
  },
  overrides: {
    RaFilterForm: {
      form: {
        "& .MuiInputBase-root": {
          width: "150px"
        }
      }
    },
    MuiInputBase: {
      root: {
        width: "500px",
      },
      multiline: {
        height: "300px",
      },
      inputMultiline: {
        height: "280px !important",
      },
    },
    RaLayout: {
      content: {
        zIndex: 111,
        backgroundColor: "#fafafa",
      },
    },
  },
};

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      theme={customTheme}
      authProvider={authProvider}
      customRoutes={customRoutes}
      menu={Menu}
    >
      <Resource
        name="user"
        list={User.ListView}
        create={User.CreateForm}
        edit={User.EditForm}
        show={User.ShowView}
      />
      <Resource
        name="corporate"
        list={Corporate.ListView}
        create={Corporate.CreateForm}
        edit={Corporate.EditForm}
        show={Corporate.ShowView}
      />
      <Resource
        name="merchant"
        list={Merchant.ListView}
        create={Merchant.CreateForm}
        edit={Merchant.EditForm}
      />
      <Resource
        name="lucky-draw"
        list={LuckyDraw.ListView}
      />
      <Resource
        name="cuisine"
        list={Cuisine.ListView}
        create={Cuisine.CreateForm}
      />
      <Resource
        name="district"
        list={District.ListView}
        create={District.CreateForm}
      />
      <Resource
        name="good-for"
        options={{ label: "Good For" }}
        list={GoodFor.ListView}
        create={GoodFor.CreateForm}
      />

      <Resource
        name="merchant-type"
        options={{ label: "Merchant Type" }}
        list={MerchantType.ListView}
      // create={MerchantType.CreateForm}
      />
      <Resource
        name="promotional-code"
        list={PromotionalCode.ListView}
        create={PromotionalCode.CreateForm}
        edit={PromotionalCode.EditForm}
      />
      <Resource
        name="pricing-scheme"
        list={PricingScheme.ListView}
        create={PricingScheme.CreateForm}
        edit={PricingScheme.EditForm}
      />
      <Resource
        name="voucher"
        list={Voucher.ListView}
        create={Voucher.CreateForm}
        edit={Voucher.EditForm}
      />
      <Resource
        name="article"
        list={Article.ListView}
        create={Article.CreateForm}
        edit={Article.EditForm}
      />
      <Resource
        name="faq"
        list={FAQ.ListView}
        create={FAQ.CreateForm}
        edit={FAQ.EditForm}
      />
      <Resource name="record" list={Record.ListView} />
    </Admin>
  );
}
export default App;
