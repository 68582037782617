const checkFilterOrSortExists = (resource, filter, sort) => {
  switch (resource) {
    case 'merchant':
      return filter.name || filter.formName || sort.field;
    case 'user':
      return filter.fullname || filter.corporate_name || sort.field;
    default:
      return false;
  }
}

const isDataMatchedWithFilter = (data, filter) => {
  let result = false;
  Object.keys(filter).map((filterField, index) => {
    let value = data[filterField];
    let filterValue = filter[filterField];
    if (!value) result = false;
    // else if (filterField === 'corporate_name' && value[0]) {
    let field = checkFilterIsPopulatedAndCarryValue(value[0]);
    if (field.hasValue) {
      value = field.value['name'];
      result = checkEquality(value, filterValue);
    }
    else if (!field.hasValue && field.type === 'string' && Object.keys(filter).length == 1) {
      result = checkEquality(value, filterValue);
    }
    else if (!field.hasValue || index > 0 && result == false) {
      result = false
    }
    // else {
    //   result = value.toLowerCase().includes(filter[filterField].toLowerCase());
    // }
  })

  return result ? true : false;
}

const checkFilterIsPopulatedAndCarryValue = (value) => {
  if (typeof value === 'string' || !value)
    return { hasValue: false, type: typeof value };
  else {
    return { hasValue: true, value }
  }
}

const checkEquality = (value, filter) => {
  return value.toLowerCase().includes(filter.toLowerCase());
}
const sortData = (data, sort) => {
  const sorted = data.sort(function (a, b) {

    if (!a[sort.field] && b[sort.field]) return 1;
    else if (a[sort.field] && !b[sort.field]) return -1;
    else if (!a[sort.field] && !b[sort.field]) return 0;
    if (sort.field === 'role')
      return characterComparison(a[sort.field]['name'].toLowerCase(), b[sort.field]['name'].toLowerCase(), 0);
    else return characterComparison(a[sort.field].toLowerCase(), b[sort.field].toLowerCase(), 0);

  })

  if (sort.order === 'DESC') {
    return sorted.reverse();
  }
  return sorted;
}

const characterComparison = (a, b, index) => {

  const value = a.charCodeAt(index) - b.charCodeAt(index);
  if (value == 0)
    return characterComparison(a, b, ++index);

  return value;
}

export { checkFilterOrSortExists, isDataMatchedWithFilter, sortData }