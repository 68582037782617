import fetchAPI from "../config/api";

export const insertToRange = (quill, url) => {
  const range = quill.getSelection();
  quill.insertEmbed(
    range.index,
    "image",
    `${process.env.REACT_APP_BASE_URI}/${url}`
  );
};

export const saveToServer = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await fetchAPI.uploadImage(formData);
  return res;
};

export const uploadImage = (quill) => {
  quill.getModule("toolbar").addHandler("image", function (val) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (/^image\//.test(file.type)) {
        const res = await saveToServer(file, "image");
        const json = await res.json();
        insertToRange(quill, json.path);
      } else {
        console.warn("Only images can be uploaded here.");
      }
    };
  });
};
