import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectInput,
  required,
} from "react-admin";

const CreateForm = (props) => {
  const formatted = (ps) => {
    return `$${ps.price} ----- ${ps.descriptive_title}`;
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ArrayInput source="email" validate={required()}>
          <SimpleFormIterator>
            <TextInput label="Email Suffix" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          source="discount_rate"
          reference="pricing-scheme"
        >
          <SelectInput
            optionText={formatted}
            validate={required()}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateForm;
