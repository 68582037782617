import React from "react";
import { Layout, MenuItemLink, Responsive } from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreFront from "@material-ui/icons/Storefront";
import Fastfood from "@material-ui/icons/Fastfood";
import Room from "@material-ui/icons/Room";
import ConfirmationNumber from "@material-ui/icons/ConfirmationNumber";
import LiveHelp from "@material-ui/icons/LiveHelp";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Description from "@material-ui/icons/Description";
import Business from "@material-ui/icons/Business";
import AccountTree from "@material-ui/icons/AccountTree";
import GroupIcon from '@material-ui/icons/Group';
import CasinoIcon from '@material-ui/icons/Casino';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const menuItems = [
  { name: "user", text: "Users", icon: <GroupIcon /> },
  { name: "article", text: "Articles", icon: <Description /> },
  { name: "corporate", text: "Corporates", icon: <Business /> },
  { name: "cuisine", text: "Cuisines", icon: <Fastfood /> },
  { name: "district", text: "Districts", icon: <Room /> },
  { name: "faq", text: "FAQ", icon: <LiveHelp /> },
  { name: "general", text: "General", icon: <SettingsIcon /> },
  { name: "good-for", text: "Good For", icon: <CheckCircle /> },
  { name: "merchant", text: "Merchants", icon: <StoreFront /> },
  { name: "merchant-type", text: "Merchant Type", icon: <BookIcon /> },
  { name: "voucher", text: "Vouchers", icon: <ConfirmationNumber /> },
  { name: "record", text: "Record", icon: <AccountTree /> },
  { name: "lucky-draw", text: "Lucky draw", icon: <CasinoIcon /> },
  { name: "pricing-scheme", text: "Pricing Scheme", icon: <CreditCardIcon /> },
  { name: "promotional-code", text: "Promotional Code", icon: <CreditCardIcon /> },
];

const MyMenu = ({ onMenuClick, logout }) => (
  <>
    {menuItems.map((item) => (
      <MenuItemLink
        key={item.name}
        to={`/${item.name}`}
        primaryText={item.text}
        leftIcon={item.icon}
        onClick={onMenuClick}
      />
    ))}
    <Responsive small={logout} medium={null} />
  </>
);

const MyLayout = (props) => {
  return <Layout {...props} menu={MyMenu} />;
};

export default MyLayout;
