import React from "react";
const baseURL = process.env.REACT_APP_BASE_URI;

const PhotoEditField = (props) => {
  const img = props.record;

  if (typeof img === "string") {
    //   saved value
    return <img width="300" src={`${baseURL}/${img}`} alt={"img"} />;
  }

  return <img width="300" src={img.undefined} alt={"img"} />;
};

export default PhotoEditField;
