import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  EditButton,
  DeleteButton,
  SearchInput,
  DateInput,
  useRecordContext,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import FilterSiderbar from "./FilterSidebar";
import { Typography } from '@mui/material';
const moment = require('moment-timezone')

const ClaimedTime = ({ record }) => {
  return <Typography>{moment.tz(record.created_at, "Asia/Hong_Kong").format('DD/MM/YYYY')}</Typography>
  // console.log('log record', record);
  // if (record.created_at) {
  //   const codesThatHaveBeenClaimed = record.created_at;
  //   return codesThatHaveBeenClaimed.length && codesThatHaveBeenClaimed.map(({ date }) => (
  //     <Typography>{moment.tz(date, "Asia/Hong_Kong").format('DD/MM/YYYY, h:mm:ss a')}</Typography>
  //   ))
  // } else return <></>
}

const filters = [
  <ReferenceInput
    label="Voucher"
    source="voucher"
    reference="voucher"
    filterToQuery={(searchText) => ({ name: searchText })}
    allowEmpty={true}
  >
    <AutocompleteInput optionText="title" />
  </ReferenceInput>,
  <TextInput label="Merchant" source="merchant" />,
  <DateInput label="Used_after" source="used_after" />,
  <DateInput label="Used_before" source="used_before" />,
];

const FullNameField = (props) => {
  const record = useRecordContext(props);

  return (
    <span>
      {record.user.fname} {record.user.lname}
    </span>
  );
};

const ListView = (props) => {
  return (
    <List {...props} filters={filters} bulkActionButtons={false}>
      <Datagrid>
        <FullNameField label="Name" />
        <TextField source="voucher.title" label="Voucher" />
        <TextField source="merchant.name" label="Merchant" />
        {/* <DateField source="createdAt" label="Used Date" /> */}
        <ClaimedTime source="created_at" label="Used Date" />
      </Datagrid>
    </List>
  );
};

export default ListView;
